import React from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Input } from "antd";

import ReportOptions from "components/TabsExport/components/ReportOptions";
import ScheduleAlert from "components/Alerts/components/ScheduleAlert";
import FiltersAlert from "components/Alerts/components/FiltersAlert";
import DateSelect from "components/TabsExport/components/DateSelect";
import UsersList from "components/TabsExport/components/UsersList";
import EmailInputs from "components/TabsExport/components/EmailInputs";
import ReportData from "components/TabsExport/components/ReportData";
import { STATE_STATUSES } from "utils/statuses";

import { filtersTemplate, sectionsSummary } from "components/TabsExport/exportSections";
import LoaderBox from "components/LoaderBox";

const TemplateReport = ({
  setFiltersParamsDate,
  setFiltersParams,
  handleSetMembers,
  handleDeleteInput,
  handleAddInput,
  handleChangeInput,
  setScheduleDays,
  setScheduleType,
  handleSetOptions,
  name,
  setName,
  filterParams,
  filters,
  setSectionId,
  sectionId,
  options,
  companyMembers,
  users,
  schedule,
  emails,
  disabled,
}) => {
  const { email, retailers } = useSelector((state) => state.settingsYourProfile.settingsYourProfile);
  const { selectAllProducts, selectAllBrands } = useSelector((state) => state.exports);
  const filtersStatus = useSelector((state) => state.exports.filtersStatus);
  return (
    <>
      <div className="export-hint" style={{ fontFamily: "Gilroy-Medium", fontWeight: "bold" }}>
        Please enter details below to schedule a regular automated report.
      </div>
      {options[sectionsSummary[sectionId]] && (
        <>
          <div className="export-item">
            <div className="number">1</div>
            <div className="text">Report Name</div>
          </div>
          <div
            className="export-hint"
            style={{ fontFamily: "Gilroy-Medium", fontWeight: "bold", marginBottom: "14px" }}
          >
            Enter name
          </div>
          <Input
            className="export-input"
            style={{ maxWidth: "300px" }}
            placeholder="E.g. My Full Report"
            disabled={disabled}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <div className="export-item">
            <div className="number">2</div>
            <div className="text">Report Filters</div>
          </div>
          <div className="export-hint" style={{ fontFamily: "Gilroy-Medium", fontWeight: "bold" }}>
            Select Filters. Note: Initial settings are inherited from your current filter selections
          </div>
          <DateSelect disabled={disabled} activeDate={filterParams.timePeriod} setActiveDate={setFiltersParamsDate} />
          {filtersStatus !== STATE_STATUSES.READY ? (
            <LoaderBox />
          ) : (
            <div className="selects-box">
              {filtersTemplate(
                _.sortBy(filters.sourceType, (item) => retailers.indexOf(item.id)),
                filters.category,
                filters.manufacture,
                filters.productBrand,
                filters.productGroup,
                filters.product
              ).map((item, index) => {
                const selectAllProp =
                  item.name === "productBrand" ? selectAllBrands : item.name === "product" ? selectAllProducts : false;
                return (
                  <FiltersAlert
                    disabled={disabled}
                    key={index}
                    item={item}
                    setFiltersParams={setFiltersParams}
                    selectItems={filterParams[item.name]}
                    hasAllCheckbox={item.name === "product" || item.name === "productBrand"}
                    selectAllProp={selectAllProp}
                  />
                );
              })}
            </div>
          )}
        </>
      )}

      <ReportData
        current={options[sectionsSummary[sectionId]] ? 3 : 1}
        setSectionId={setSectionId}
        sectionId={sectionId}
        options={options}
        disabled={disabled}
      />

      {options[sectionsSummary[sectionId]] && (
        <>
          <ReportOptions
            current={options[sectionsSummary[sectionId]] ? 4 : 2}
            sectionId={sectionId}
            handleSetOptions={handleSetOptions}
            options={options}
            amountDays={16}
            disabled={disabled}
            isFullHistory={
              isNaN(filterParams.timePeriod) &&
              filterParams.timePeriod.split("|").length > 3 &&
              filterParams.timePeriod.split("|")[3] === "ful"
            }
          />
          <div className="export-item">
            <div className="number">5</div>
            <div className="text">Report Sharing (Optional)</div>
          </div>
          <div className="export-hint" style={{ fontFamily: "Gilroy-Medium" }}>
            Would you like to share your report with other Brand Nudge users within your Company? <br />
            Shared Reports will be available to run within the user`s "Saved Reports" section
          </div>

          <UsersList disabled={disabled} list={companyMembers} selected={users} setSelected={handleSetMembers} />

          <div className="export-item">
            <div className="number">6</div>
            <div className="text">Report Schedule (Optional)</div>
          </div>
          <div className="export-hint" style={{ fontFamily: "Gilroy-Medium" }}>
            Would you like this report to be automatically sent to you via e-mail on a regular basis?
            <br />
            If so, please select your frequency requirements.
            <br />
            You can also distribute a copy of your report to other users at the same time. Simply add their
            <br />
            e-mail address(es) below.
            <br />
            <br />
            Please Note: Scheduled reports run at 2pm on the day selected.
          </div>
          <div className="export-label" style={{ marginTop: "20px" }}>
            How often would you like to receive this report?
          </div>
          <div className="export-group-input">
            <ScheduleAlert
              disabled={disabled}
              type={schedule["type"]}
              days={schedule["days"]}
              setType={setScheduleType}
              setDays={setScheduleDays}
              errorField={{ isEmptyOften: false, isEmptyDay: false }}
              noTime={true}
              isDays={schedule.type === "weekly"}
            />
          </div>

          <EmailInputs
            disabled={disabled}
            handleAddInput={handleAddInput}
            handleDeleteInput={handleDeleteInput}
            handleChangeInput={handleChangeInput}
            emails={emails}
            email={email}
          />
        </>
      )}
    </>
  );
};
export default TemplateReport;
